import { getParents } from '../Utility/getParents'

/**
 * SubMenu related functions and events
 */
export const submenu = () => {
  // Exit if no submenu is present (e.g. on the start page)
  if (!document.querySelector('.sub-menu__bar')) { return }

  const toolbar = document.querySelector('.toolbar__wrap')
  const topicButton = document.querySelector('.sub-menu__topic-button')
  const subMenuNavWrap = document.querySelector('.sub-menu__nav-wrap')
  const subMenuNav = document.querySelector('.sub-menu__nav')
  const subMenuButtonsNext = document.querySelectorAll('.sub-menu__button--next')
  const subMenuButtonsBack = document.querySelectorAll('.sub-menu__button--back')
  const subMenuFirstList = document.querySelector('.sub-menu__list-wrap > .sub-menu__list')
  const subMenuCurrentLink = document.querySelector('.sub-menu__link--current') || document.querySelector('.sub-menu__link--active')

  const getCurrentFirstFocusableElements = () => {
    const focusableEls = document.querySelectorAll('.sub-menu__nav-wrap a[href], .sub-menu__nav-wrap button')
    const myArray = [].slice.call(focusableEls)
    const elementsOnShow = myArray.filter(element => element.offsetWidth > 0 || element.offsetHeight > 0 || element.getClientRects().length > 0)
    return { firstFocusableEl: elementsOnShow[0], lastFocusableEl: elementsOnShow[elementsOnShow.length - 1] }
  }

  const showCurrentSubMenuList = (currentList) => {
    currentList.style.display = 'block'

    const currentLinkParents = getParents(currentList)

    currentLinkParents.forEach(parent => {
      if (parent.matches('.sub-menu__list, .sub-menu__item')) {
        parent.style.display = 'block'
      }
    })

    const closestListItems = currentList.querySelectorAll(':scope > .sub-menu__item')

    closestListItems.forEach(item => {
      item.classList.add('visible')
      item.style.display = 'block'

      const button = item.querySelector(':scope > .sub-menu__button')
      if (button) {
        button.style.display = 'flex'
      }

      const link = item.querySelector(':scope > .sub-menu__link')
      if (link) {
        link.style.display = 'block'
      }
    })

    if (getCurrentFirstFocusableElements().firstFocusableEl) {
      getCurrentFirstFocusableElements().firstFocusableEl.focus()
    }
  }

  if (subMenuCurrentLink) {
    showCurrentSubMenuList(subMenuCurrentLink.closest('.sub-menu__list'))
  } else {
    showCurrentSubMenuList(subMenuFirstList)
  }

  const hideAllSubmenuNodes = () => {
    const subMenuLists = document.querySelectorAll('.sub-menu__list')
    const subMenuItems = document.querySelectorAll('.sub-menu__item')
    const subMenuButtons = document.querySelectorAll('.sub-menu__button')

    subMenuLists.forEach(list => {
      list.style.display = 'none'
    })

    subMenuItems.forEach(item => {
      item.classList.remove('visible')
      item.style.display = 'none'
    })

    subMenuButtons.forEach(button => {
      button.style.display = 'none'
    })
  }

  const openSubMenuNav = () => {
    subMenuNavWrap.style.visibility = 'visible'

    topicButton.classList.add('sub-menu__topic-button--open')
    subMenuNavWrap.classList.add('sub-menu__nav-wrap--open')
    document.body.classList.add('prevent-scroll')

    window.addEventListener('click', handleClickOutside)
    window.addEventListener('resize', handleResize)
    window.addEventListener('keydown', handleKeyDown)
  }

  const closeSubMenuNav = () => {
    topicButton.classList.remove('sub-menu__topic-button--open')
    subMenuNavWrap.classList.remove('sub-menu__nav-wrap--open')
    document.body.classList.remove('prevent-scroll')

    setTimeout(() => {
      subMenuNavWrap.style.visibility = 'hidden'
    }, 400)

    window.removeEventListener('click', handleClickOutside)
    window.removeEventListener('resize', handleResize)
    window.removeEventListener('keydown', handleKeyDown)
  }

  const handleClickOutside = (e) => {
    if (!subMenuNav.contains(e.target) && !topicButton.contains(e.target)) {
      closeSubMenuNav()
    }
  }

  const handleResize = () => {
    closeSubMenuNav()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      closeSubMenuNav()
    }

    if (e.shiftKey && e.key === 'Tab') {

      if (document.activeElement === getCurrentFirstFocusableElements().firstFocusableEl) {
        getCurrentFirstFocusableElements().lastFocusableEl.focus()
        e.preventDefault()
      }
    }

    if (e.key === 'Tab') {
      if (document.activeElement === getCurrentFirstFocusableElements().lastFocusableEl) {
        getCurrentFirstFocusableElements().firstFocusableEl.focus()
        e.preventDefault()
      }
    }

    if (e.key === 'ArrowRight') {
      if (event.target.classList.contains('sub-menu__button--next')) {
        e.preventDefault()
        e.target.click()
      }
    }

    if (e.key === 'ArrowLeft') {
      if (event.target.classList.contains('sub-menu__button--back')) {
        e.preventDefault()
        e.target.click()
      }
    }
  }

  topicButton.addEventListener('click', e => {
    if (topicButton.classList.contains('sub-menu__topic-button--open')) {
      closeSubMenuNav()
    } else {
      openSubMenuNav()
    }
  })

  const openNextSubMenu = (nextButton) => {
    hideAllSubmenuNodes()
    showCurrentSubMenuList(nextButton.nextElementSibling)
  }

  subMenuButtonsNext.forEach((nextButton) => {
    nextButton.addEventListener('click', function () {
      openNextSubMenu(this)
    })
  })

  const closeCurrentSubMenu = (backButton) => {
    const currentList = backButton.closest('.sub-menu__list')
    const previousList = currentList.parentElement.closest('.sub-menu__list')
    hideAllSubmenuNodes()
    showCurrentSubMenuList(previousList)
  }

  subMenuButtonsBack.forEach((backButton) => {
    backButton.addEventListener('click', function () {
      closeCurrentSubMenu(this)
    })
  })

  /**
   * Handle the scrolling and animate the sub menu and show/hide the toolbar
   * The timeout is used to prevent the animation from being triggered multiple times
   * and to prevent focus on the hidden toolbar (A11Y).
   */

  // save last scroll position
  let lastScrollTop = 0

  // timeout that's clearable
  let timeout = null

  const handleScroll = () => {
    // Get current scroll position
    const scrollTop = document.documentElement.scrollTop

    // Check if the scroll position is greater than last scroll position
    if (scrollTop > lastScrollTop) {
      // Hide toolbar when scrolling down
      document.documentElement.classList.add('hidden-toolbar')

      // Prevent toolbar focus
      if (!timeout) {
        timeout = setTimeout(() => {
          toolbar.style.visibility = 'hidden'
        }, 4000)
      }
    } else {
      // Clear timeout when scrolling up
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }

      // Allow toolbar focus again
      toolbar.style.visibility = null

      // Show toolbar when scrolling up
      document.documentElement.classList.remove('hidden-toolbar')
    }

    // Save last scroll position for next scroll event
    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
  }

  // Call handleScroll on load
  handleScroll()

  // Call handleScroll on scroll
  window.addEventListener('scroll', () => {
    handleScroll()
  }, false)
}
