/**
 * Folds/unfolds longer content in Text & Media Element
 */
export const textExpander = () => {

  const expandableTexts = document.querySelectorAll('.textmedia__expandable')
  const expandableTextTriggers = document.querySelectorAll('.textmedia__unfold')

  expandableTexts.forEach(foldTexts)
  expandableTextTriggers.forEach(unfoldTexts)

  function foldTexts (expandableText) {
    if (expandableText.offsetHeight < expandableText.scrollHeight) {
      expandableText.classList.add('textmedia__expandable--folded')
    }
  }

  function unfoldTexts (expandableTextTrigger) {
    expandableTextTrigger.addEventListener('click', function () {
      const expandableText = expandableTextTrigger.closest('.textmedia__expandable')
      expandableText.classList.remove('textmedia__expandable--folded')
      expandableText.style.maxHeight = expandableText.scrollHeight + 'px'
      setTimeout(() => { expandableText.style.maxHeight = 'unset' }, 400)
    })
  }
}

export const addTextmediaReverseClass = () => {

  // Get all beside textmedia elements neighbors
  const textMediaBeside = document.querySelectorAll('.frame-textmedia-beside + .frame-textmedia-beside')

  // Check all beside textmedia elements neighbors
  textMediaBeside.forEach(textmediaBeside => {
    // If the previous element is a textmedia element that is not reversed reverse the current element
    if (!textmediaBeside.previousElementSibling.classList.contains('frame-textmedia-beside--reverse')) {
      textmediaBeside.classList.add('frame-textmedia-beside--reverse')
    }
  })
}
