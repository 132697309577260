/**
 *  Remove unused description and link from image in 'stage-image' and link in 'text-media'
 */

export class Image {
  // eslint-disable-next-line no-useless-constructor
  constructor () {}

  removeDescription () {
    document.querySelectorAll('.stage-image .ce-gallery .image-caption').forEach(
      item => {
        item.remove()
      },
    )
  }

  removeLink () {
    document.querySelectorAll('.stage-image .ce-gallery a, .textmedia .ce-gallery a').forEach(
      item => {
        item.replaceWith(...item.childNodes)
      },
    )
  }

  init () {
    this.removeDescription()
    this.removeLink()
  }
}
