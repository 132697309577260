import { initjQueryEventExtensions } from './Modules/jQueryEventExtensions'
import { reserveHeaderHeight, setViewWidth } from './Modules/utility'
import { globalSearch } from './Modules/global-search'
import { menu } from './Modules/menu'
import { submenu } from './Modules/submenu'
import { languageMenu, languageSuggest } from './Modules/language-menu'
import { processTables } from './Modules/tables'
import { handleForms } from './Modules/forms'
import { DisabledButtons } from './Modules/buttons'
import { Image } from './Modules/image'
import { gallery } from './Modules/gallery'
import { teaser } from './Modules/teaser'
import { news } from './Modules/news'
import { accordion } from './Modules/accordion'
import { footer } from './Modules/footer'
import { addTextmediaReverseClass, textExpander } from './Modules/textmedia'
import { donationCta } from './Modules/donationCta'
import { donationInfo } from './Modules/donationInfo'
import { donationForm } from './Modules/donationForm'
import { donationCarousel } from './Modules/donationCarousel'
import { headerSlider } from './Modules/headerSlider'
//import { powermail } from './Modules/powermail'
import { videoSwiper } from './Modules/video'
//import { changeFormButtons } from './Modules/changeFormButtons'
import { donationFrame } from './Modules/donationFrame'
import { membershipApplication } from './Modules/membership-application'
import { parsleysettings } from './Modules/parsley-settings'
//import { imageCopyright } from './Modules/imageCopyright.js'
//import { solrSearch } from './Modules/solrSearch.js'
import { initTelephoneLinks } from './Modules/telephoneLinks.js'
import { pageCategory } from './Modules/pageCategory.js'

initjQueryEventExtensions()
setViewWidth()
globalSearch()
menu()
submenu()
languageMenu()
languageSuggest()
reserveHeaderHeight()
processTables()
handleForms()
new DisabledButtons().init()
new Image().init()
gallery()
teaser()
news()
accordion()
footer()
textExpander()
addTextmediaReverseClass()
donationCta()
donationInfo()
donationForm()
donationCarousel()
headerSlider()
//powermail()
videoSwiper()
//changeFormButtons()
donationFrame()
membershipApplication()
//imageCopyright()
//solrSearch()
parsleysettings()
initTelephoneLinks()
pageCategory()
