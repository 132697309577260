import { fadeOut, fadeIn } from '../Utility/animations'

/**
 * Menu
 */
export const menu = () => {
  const mainMenu = document.querySelector('.main-menu')

  // Exit if no main menu
  if (!mainMenu) return

  const toolbar = document.querySelector('.toolbar')
  const toolbarSearchButton = document.querySelector('.toolbar__search-button')
  const navSearchButton = document.querySelector('.toolbar__nav-search-button')
  const navSearchButtonTitle = navSearchButton.title
  const navSearchButtonTitleClose = navSearchButton.getAttribute('data-title-close')
  const donateButton = document.querySelector('.toolbar__donate-btn')
  const mainMenuWrap = document.querySelector('.main-menu__wrap')
  const focusableEls = document.querySelectorAll('.toolbar a[href], .toolbar button, .main-menu__wrap a[href], .main-menu__wrap button, .main-menu__wrap input')
  const firstFocusableEl = focusableEls[0]
  const lastFocusableEl = focusableEls[focusableEls.length - 1]

  const handleClickOutside = (e) => {
    if (!mainMenuWrap.contains(e.target)) {
      closeMobileMenu()
    }
  }

  const handleResize = () => {
    closeMobileMenu()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      closeMobileMenu()
    } else if (e.shiftKey && e.key === 'Tab') {
      if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus()
        e.preventDefault()
      }
    } else if (e.key === 'Tab') {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus()
        e.preventDefault()
      }
    }
  }

  const openMobileMenu = () => {
    navSearchButton.classList.add('toolbar__nav-search-button--open')
    navSearchButton.title = navSearchButtonTitleClose
    toolbar.classList.add('toolbar--menu--open')
    if (donateButton) { fadeOut(donateButton) }
    mainMenuWrap.style.display = 'block'

    setTimeout(function () {
      mainMenuWrap.classList.add('main-menu__wrap--open')
    }, 20)

    document.body.classList.add('prevent-scroll')

    window.addEventListener('click', handleClickOutside)
    window.addEventListener('resize', handleResize)
    window.addEventListener('keydown', handleKeyDown)
  }

  const closeMobileMenu = () => {
    navSearchButton.classList.remove('toolbar__nav-search-button--open')
    navSearchButton.title = navSearchButtonTitle
    toolbar.classList.remove('toolbar--menu--open')
    if (donateButton) { fadeIn(donateButton) }
    mainMenuWrap.classList.remove('main-menu__wrap--open')

    mainMenuWrap.addEventListener('transitionend', function (e) {
      mainMenuWrap.style.display = 'none'
    }, {
      once: true,
    })

    document.body.classList.remove('prevent-scroll')

    window.removeEventListener('click', handleClickOutside)
    window.removeEventListener('resize', handleResize)
    window.removeEventListener('keydown', handleKeyDown)
  }

  navSearchButton.addEventListener('click', e => {
    e.stopPropagation()

    if (navSearchButton.classList.contains('toolbar__nav-search-button--open')) {
      closeMobileMenu()
    } else {
      openMobileMenu()
    }
  })

  const handleResponsive = () => {
    if (window.matchMedia('(min-width: 993px)').matches) {
      toolbarSearchButton.before(mainMenu)
    } else {
      mainMenuWrap.appendChild(mainMenu)
    }
  }

  handleResponsive()
  window.addEventListener('resize', handleResponsive)
}
