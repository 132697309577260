import Swiper, { Pagination, FreeMode } from 'swiper'

export const teaser = () => {
  if (!document.querySelector('.swiper__teaser')) { return }

  // do not show a transition between slides if the user has opt out of animations
  const allSwiperElements = document.querySelectorAll('.swiper__teaser')

  for (let i = 0; i < allSwiperElements.length; i++) {
    const swiperId = allSwiperElements[i].dataset.swiperId
    const teaserAnnouncements = document.getElementById(`teaserAnnouncements-${swiperId}`)

    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(allSwiperElements[i], {
      modules: [Pagination, FreeMode],
      slidesPerView: 'auto',
      freeMode: true,
      centeredSlides: true,
      spaceBetween: 16,
      grabCursor: true,
      preloadImages: false,
      on: {
        // announce current silde for screenreader users
        slideChange: function () {
          const currentSlide = this.realIndex + 1

          // eslint-disable-next-line no-undef
          teaserAnnouncements.textContent = teaserImage + ' ' + currentSlide
        },
      },
    })
  }
}
