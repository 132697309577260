/**
 * Content Element Accordions
 */

export const accordion = () => {
  // collect all accordion buttons
  const accordionTrigger = $('.ce-acco__trigger')

  // assign listeners for click events to each button
  accordionTrigger.each(function () {
    $(this).click(function () {
      toggleAccordion($(this))
    })
  })

  const toggleAccordion = (trigger) => {
    // select accordion parts
    const expanded = trigger.attr('aria-expanded') === 'true'
    const content = trigger.parent().next()

    // toggle all attributes
    trigger.attr('aria-expanded', !expanded)
    content.attr('aria-expanded', (!expanded).toString())
    content.attr('aria-hidden', expanded.toString())

    // open and closing animation
    $(content).stop().slideToggle(300)
  }
}
