import Swiper, {FreeMode, Pagination} from "swiper";

export const donationInfo = () => {
  if (!document.querySelector('.donation-info__swiper')) {return}

  const allSwiperElements = document.querySelectorAll('.donation-info__swiper')

  for (let i = 0; i < allSwiperElements.length; i++) {

    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(allSwiperElements[i], {
      modules: [Pagination, FreeMode],
      slidesPerView: 'auto',
      grabCursor: true,
      centerInsufficientSlides: true,
    })
  }
}
