/**
 *  Prevent default action for disabled buttons as 'a' tags
 */

export class DisabledButtons {
  // eslint-disable-next-line no-useless-constructor
  constructor () {}

  preventDisabledButtons () {
    document.querySelectorAll('a.button--disabled, a:disabled').forEach(
      item => {
        item.addEventListener('click', (e) => {
          e.preventDefault()
        })
      },
    )
  }

  init () {
    this.preventDisabledButtons()
  }
}
