export const donationForm = () => {

  const donationForm = document.querySelector('#card_token-form')

  // exit if donation form not existing
  if (!donationForm) {
    return
  }

  const donationTypeUser = document.querySelector('#user')
  const donationTypeUserSecret = document.querySelector('#user-secret')
  const donationTypeCompany = document.querySelector('#company')
  const companyAddress = document.querySelector('.donation-form__data-item--company.visually-hidden.h-hide')
  const personalData = document.querySelector('.donation-form__section--personal-data')
  const paymentFrequencyOnce = document.querySelector('#form-donation-mode_1')
  const paymentFrequencies = document.querySelectorAll('.donation-form__frequency-item--monthly, .donation-form__frequency-item--quarterly, .donation-form__frequency-item--yearly')
  const paymentButtons = document.querySelectorAll('.donation-form__payment-button')
  const paymentSepa = document.querySelector('#jsPayment__sepa')
  const paymentCreditCard = document.querySelector('#jsPayment__creditcard')

  // toggle address information for donation type
  if (donationTypeUser) {
    donationTypeUser.addEventListener('click', () => {
      personalData.classList.remove('visually-hidden', 'h-hide')
      companyAddress.classList.add('visually-hidden', 'h-hide')

      // reveal all payment methods
      paymentButtons.forEach(button => {
        button.classList.remove('visually-hidden', 'h-hide')
      })

      // eslint-disable-next-line no-return-assign
      paymentFrequencies.forEach(mode => mode.style.display = 'flex')
    })
  }

  if (donationTypeUserSecret) {
    donationTypeUserSecret.addEventListener('click', () => {
      personalData.classList.add('visually-hidden', 'h-hide')

      // hide other non anonym payment methods
      paymentButtons.forEach(button => {
        if (!button.classList.contains('anonym')) {
          button.classList.add('visually-hidden', 'h-hide')
        } else {
          button.classList.remove('visually-hidden', 'h-hide', 'donation-form__payment-button--inactive')
        }
      })

      document.querySelector('.donation-form__item--sepa').classList.add('h-hide')
      document.querySelector('.donation-form__item--creditcard-group').classList.add('h-hide')

      // eslint-disable-next-line no-return-assign
      paymentFrequencies.forEach(mode => mode.style.display = 'none')

      // validate frequency mode once if not already selected
      if (!paymentFrequencyOnce.checked) {
        paymentFrequencyOnce.checked = true
        paymentFrequencyOnce.nextElementSibling.classList.add('active')
      }
    })
  }

  if (donationTypeCompany) {
    donationTypeCompany.addEventListener('click', () => {
      personalData.classList.remove('visually-hidden', 'h-hide')
      if (companyAddress.classList.contains('visually-hidden') && companyAddress.classList.contains('h-hide')) {
        companyAddress.classList.remove('visually-hidden', 'h-hide')
      }

      // reveal all payment methods
      paymentButtons.forEach(button => {
        button.classList.remove('visually-hidden', 'h-hide')
      })

      // eslint-disable-next-line no-return-assign
      paymentFrequencies.forEach(mode => mode.style.display = 'flex')
    })
  }

  // toggle payment button active status
  if (paymentButtons) {
    paymentButtons.forEach(button => {

      button.addEventListener('click', () => {
        const activeButton = button

        activeButton.classList.add('donation-form__payment-button--active')
        paymentButtons.forEach(button => {
          if (button !== activeButton) {
            button.classList.add('donation-form__payment-button--inactive')
            button.classList.remove('donation-form__payment-button--active')
          } else {
            button.classList.remove('donation-form__payment-button--inactive')
          }
        })
      })
    })
  }

  // reveal all payment methods if payment frequency changes from once to monthly, quarterly or yearly
  if (paymentFrequencyOnce) {
    paymentFrequencyOnce.addEventListener('click', () => {
      paymentButtons.forEach(button => {
        button.classList.remove('h-hide', 'visually-hidden', 'donation-form__payment-button--inactive')
      })
    })
  }

  if (paymentSepa) {
    paymentSepa.addEventListener('click', () => {
      if (document.querySelector('.donation-form__item--sepa.h-hide')) {
        document.querySelector('.donation-form__item--sepa').classList.remove('h-hide')
      }
    })
  }

  if (paymentCreditCard) {
    paymentCreditCard.addEventListener('click', () => {
      if (document.querySelector('.donation-form__item--creditcard-group.h-hide')) {
        document.querySelector('.donation-form__item--creditcard-group').classList.remove('h-hide')
      }
    })
  }

  // reduce paddings of donation fine print (all gray frames that have direct siblings)
  // TODO: delete this when the client has fixed that by using a single text element
  const greyBgFrames = document.querySelectorAll('.frame.bg--lightgrey.frame-type-textmedia')
  greyBgFrames.forEach((frame, index) => {
    if (frame.nextElementSibling.classList.contains('frame-type-textmedia')) {
      frame.style.paddingBottom = '0'
    }
  })
}
