import Swiper, { Pagination, FreeMode } from 'swiper'

export const news = () => {
  if (!document.querySelector('.news__swiper')) { return }

  // do not show a transition between slides if the user has opt out of animations
  const allSwiperElements = document.querySelectorAll('.news__swiper')

  for (let i = 0; i < allSwiperElements.length; i++) {

    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(allSwiperElements[i], {
      modules: [Pagination, FreeMode],
      slidesPerView: 'auto',
      freeMode: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      spaceBetween: 16,
      grabCursor: true,
      preloadImages: false,
      on: {
        // announce current silde for screenreader users
        slideChange: function () {
          const currentSlide = this.realIndex + 1

          // eslint-disable-next-line no-undef
          const teaserItem = document.querySelectorAll('.teaser__item')
          if (teaserItem && typeof teaserItem !== 'undefined') {
            const item = teaserItem[currentSlide]
            const newsImage = item.getElementsByTagName('img')
            if (newsImage && typeof newsImage !== 'undefined') {
              const newsAnnouncements = document.querySelectorAll('.news__announcements')
              if (newsAnnouncements && typeof newsAnnouncements !== 'undefined') {
                newsAnnouncements[0].innerHTML = newsImage[0].alt
              }
            }
          }
        },
      },
    })
  }

  const newsFrame = document.querySelectorAll('.frame-type-news_newsliststicky')
  const newsGreyContainer = document.querySelectorAll('.news__container--grey')

  // find frame for each grey news container and add grey bg
  for (let i = 0; i < newsGreyContainer.length; i++) {
    newsGreyContainer[i].classList.add('bg', 'bg--grey')
  }
}
