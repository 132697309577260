/**
 *  Wrap tables to set overflow-x for responsive
 */

export const processTables = () => {

  // wrap all contenttables with overflow-wrapper to use responsive overflow-x
  $('.contenttable').wrap('<div class="contenttable__wrap"></div>')

  // remove style attributes
  $('.ce-main table[style], .ce-main td[style]').removeAttr('style')
}
