import Swiper, { Navigation, EffectCards, Autoplay } from 'swiper'

export const donationCarousel = () => {
  if (!document.querySelector('.donation-carousel__swiper')) { return }

  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper('.donation-carousel__swiper', {
    modules: [Navigation, EffectCards, Autoplay],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    effect: 'cards',
    slidesPerView: 'auto',
    centeredSlides: true,
    grabCursor: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
  })
}
