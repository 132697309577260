import Swiper, { Autoplay, EffectFade } from 'swiper'

export const headerSlider = () => {
  if (!document.querySelector('.header-slider__swiper')) { return }

  const allSwiperElements = document.querySelectorAll('.header-slider__swiper')

  for (let i = 0; i < allSwiperElements.length; i++) {

    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(allSwiperElements[i], {
      modules: [Autoplay, EffectFade],
      loop: true,
      speed: 2000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: true,
      },
    })
  }
}
