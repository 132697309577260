/**
 * Smooth fading in with opacity
 *
 * @param el element to fade
 */
const fadeOut = el => {
  el.style.opacity = 1;
  (function fade () {
    if ((el.style.opacity -= 0.1) < 0) {
      el.style.display = 'none'
      el.style.opacity = null
    } else {
      requestAnimationFrame(fade)
    }
  })()
}

/**
 * Smooth fading in with opacity
 *
 * @param el element to fade
 * @param display target display property (default block)
 */
const fadeIn = (el, display) => {
  el.style.opacity = 0
  el.style.display = display || 'block';
  (function fade () {
    let val = parseFloat(el.style.opacity)
    if (!((val += 0.1) > 1)) {
      el.style.opacity = val
      requestAnimationFrame(fade)
    }
  })()
}

/**
 * Slide toggle shorthand
 *
 * @param {object} target element to slide
 * @param {number} duration duration (default 500ms)
 */

const slideToggle = (target, duration = 500) => {
  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration)
  } else {
    return slideUp(target, duration)
  }
}

/**
 * Slide up animation
 *
 * @param {object} target element to slide
 * @param {number} duration duration (default 500ms)
 */

const slideUp = (target, duration = 500) => {
  target.style.transitionProperty = 'height, margin, padding, border-width'
  target.style.transitionDuration = duration + 'ms'
  target.style.boxSizing = 'border-box'
  target.style.height = target.offsetHeight + 'px'
  target.offsetHeight // eslint-disable-line
  target.style.overflow = 'hidden'
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  target.style.borderTopWidth = 0
  target.style.borderBottomWidth = 0
  window.setTimeout(() => {
    target.style.display = 'none'
    target.style.removeProperty('height')
    target.style.removeProperty('padding-top')
    target.style.removeProperty('padding-bottom')
    target.style.removeProperty('margin-top')
    target.style.removeProperty('margin-bottom')
    target.style.removeProperty('border-top-width')
    target.style.removeProperty('border-bottom-width')
    target.style.removeProperty('overflow')
    target.style.removeProperty('transition-duration')
    target.style.removeProperty('transition-property')
  }, duration)
}

/**
 * Slide down animation
 *
 * @param {object} target element to slide
 * @param {number} duration duration (default 500ms)
 */

const slideDown = (target, duration = 500) => {
  target.style.removeProperty('display')
  let display = window.getComputedStyle(target).display
  if (display === 'none') display = 'block'
  target.style.display = display
  const height = target.offsetHeight
  target.style.overflow = 'hidden'
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  target.style.borderTopWidth = 0
  target.style.borderBottomWidth = 0
  target.offsetHeight // eslint-disable-line
  target.style.boxSizing = 'border-box'
  target.style.transitionProperty = 'height, margin, padding, border-width'
  target.style.transitionDuration = duration + 'ms'
  target.style.height = height + 'px'
  target.style.removeProperty('padding-top')
  target.style.removeProperty('padding-bottom')
  target.style.removeProperty('margin-top')
  target.style.removeProperty('margin-bottom')
  target.style.removeProperty('border-top-width')
  target.style.removeProperty('border-bottom-width')
  window.setTimeout(() => {
    target.style.removeProperty('height')
    target.style.removeProperty('overflow')
    target.style.removeProperty('transition-duration')
    target.style.removeProperty('transition-property')
  }, duration)
}

export {
  fadeOut,
  fadeIn,
  slideToggle,
  slideUp,
  slideDown,
}
