import { fadeOut, fadeIn, slideDown, slideUp } from '../Utility/animations'

/**
 * Menu
 */
export const globalSearch = () => {
  const globalSearch = document.querySelector('.global-search')

  // Exit if no global search
  if (!globalSearch) return

  const header = document.querySelector('#header')
  const toolbar = document.querySelector('.toolbar')
  const toolbarSearchButton = document.querySelector('.toolbar__search-button')
  const toolbarNavigation = document.querySelector('.toolbar__navigation')
  const mainMenuWrap = document.querySelector('.main-menu__wrap')
  const focusableEls = document.querySelectorAll('.global-search a[href], .global-search button, .global-search a[href], .global-search button, .global-search input')
  const firstFocusableEl = focusableEls[0]
  const lastFocusableEl = focusableEls[focusableEls.length - 1]
  const quickLinks = document.querySelector('.quicklinks')

  const handleClickOutside = (e) => {
    if (!globalSearch.contains(e.target)) {
      closeGlobalSearch()
    }
  }

  const handleResize = () => {
    closeGlobalSearch()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      closeGlobalSearch()
    } else if (e.shiftKey && e.key === 'Tab') {
      if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus()
        e.preventDefault()
      }
    } else if (e.key === 'Tab') {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus()
        e.preventDefault()
      }
    }
  }

  const openGlobalSearch = () => {
    fadeOut(toolbarNavigation)
    fadeIn(globalSearch)
    setTimeout(() => {
      if (quickLinks) {
        slideDown(quickLinks)
      }
      globalSearch.querySelector('#global-search-input').focus()
      toolbar.classList.add('toolbar--search--open')
      window.addEventListener('click', handleClickOutside)
      window.addEventListener('resize', handleResize)
      window.addEventListener('keydown', handleKeyDown)
    }, 100)
  }

  const closeGlobalSearch = () => {
    if (quickLinks) {
      slideUp(quickLinks)
    }
    window.removeEventListener('click', handleClickOutside)
    window.removeEventListener('resize', handleResize)
    window.removeEventListener('keydown', handleKeyDown)

    setTimeout(() => {
      fadeIn(toolbarNavigation, 'flex')
      fadeOut(globalSearch)
      toolbar.classList.remove('toolbar--search--open')
    }, 100)
  }

  toolbarSearchButton.addEventListener('click', e => {
    openGlobalSearch()
  })

  globalSearch.querySelector('.global-search__close').addEventListener('click', e => {
    e.preventDefault()
    closeGlobalSearch()
  })

  const handleResponsive = () => {
    if (window.matchMedia('(min-width: 993px)').matches) {
      header.appendChild(globalSearch)
      globalSearch.style.display = 'none'
    } else {
      mainMenuWrap.appendChild(globalSearch)
      globalSearch.style.display = 'block'
    }
  }

  handleResponsive()
  window.addEventListener('resize', handleResponsive)
}
