/**
 * Footer Accordions
 */

export const footer = () => {
  // collect all accordion buttons
  const accordionTrigger = $('.footer-acco__trigger')
  const accordionLabel = $('.footer-acco__label')

  // assign listeners for click events to each button
  accordionTrigger.each(function () {
    $(this).click(function () {
      toggleAccordion($(this))
    })
  })

  const toggleAccordion = (trigger) => {
    // select accordion parts
    const expanded = trigger.attr('aria-expanded') === 'true'
    const content = trigger.parent().next()

    // toggle all attributes
    trigger.attr('aria-expanded', !expanded)
    content.attr('aria-expanded', (!expanded).toString())
    content.attr('aria-hidden', expanded.toString())

    // open and closing animation
    $(content).stop().slideToggle(300)
  }

  const switchToButtons = () => {
    if (matchMedia('(min-width: 577px)').matches) {
      accordionLabel.show().removeAttr('aria-hidden')
      accordionTrigger.hide().attr('aria-hidden', 'true')
    } else {
      accordionLabel.hide().attr('aria-hidden', 'true')
      accordionTrigger.show().removeAttr('aria-hidden')
    }
  }

  switchToButtons()
  $(window).resize(switchToButtons)
}
