export const pageCategory = () => {
  if (!document.querySelector('.pagets__category')) { return }

  const firstFrameHeader = document.querySelector('.pagets__category #main > .frame .header')
  const category = document.querySelector('.page-category')

  // append category to first frame header

  if (firstFrameHeader && category) {
    firstFrameHeader.appendChild(category)
    category.style.display = 'block'
  }
}
