export const parsleysettings = () => {

  parsley.addMessages('de', {
    defaultMessage: 'Die Eingabe scheint nicht korrekt zu sein.',
    type: {
      email: 'Die Eingabe muss eine gültige E-Mail-Adresse sein.',
      url: 'Die Eingabe muss eine gültige URL sein.',
      number: 'Die Eingabe muss eine Zahl sein.',
      integer: 'Die Eingabe muss eine Zahl sein.',
      digits: 'Die Eingabe darf nur Ziffern enthalten.',
      alphanum: 'Die Eingabe muss alphanumerisch sein.',
      tel: 'Die Eingabe darf nur Ziffern enthalten.',
    },
    notblank: 'Die Eingabe darf nicht leer sein.',
    required: 'Dies ist ein Pflichtfeld.',
    pattern: 'Die Eingabe scheint ungültig zu sein.',
    min: 'Die Eingabe muss größer oder gleich %s sein.',
    max: 'Die Eingabe muss kleiner oder gleich %s sein.',
    range: 'Die Eingabe muss zwischen %s und %s liegen.',
    minlength: 'Die Eingabe ist zu kurz. Es müssen mindestens %s Zeichen eingegeben werden.',
    maxlength: 'Die Eingabe ist zu lang. Es dürfen höchstens %s Zeichen eingegeben werden.',
    length: 'Die Länge der Eingabe ist ungültig. Es müssen zwischen %s und %s Zeichen eingegeben werden.',
    mincheck: 'Wählen Sie mindestens %s Angaben aus.',
    maxcheck: 'Wählen Sie maximal %s Angaben aus.',
    check: 'Wählen Sie zwischen %s und %s Angaben.',
    equalto: 'Dieses Feld muss dem anderen entsprechen.',
  });

  if (document.documentElement.lang === 'de' || document.documentElement.lang === 'de-DE') {
    parsley.setLocale('de');
  } else {
    parsley.setLocale('en');
  }

  let  $mailForm;

  $mailForm = jQuery('.o-form');

  // Exit if no mailform form
  if (!$mailForm) return;

  if ($mailForm.length) {
    $mailForm.parsley(
      {
        trigger: 'change',
      }
    );
    $mailForm.attr('data-parsley-excluded', '[disabled]');
  }
};


