/**
 * Shows and hides donation CTA banner on scroll
 */

export const donationCta = () => {
  // Get all elements
  const donationCta = document.querySelector('.donation-cta')
  const footer = document.querySelector('#footer')

  // Exit if no donation CTA
  if (!donationCta) { return }

  // Exit on homepage
  if (!document.querySelector('.sub-menu__bar')) { return }

  const showDonationCta = () => {
    // exit if animation is already running
    if (donationCta.classList.contains('donation-cta--animating')) { return }

    donationCta.style.visibility = 'visible'
    donationCta.style.opacity = 1
    donationCta.classList.remove('donation-cta--hidden')

    // set animation class to prevent multiple animations
    donationCta.classList.add('donation-cta--animating')
    setTimeout(() => {
      donationCta.classList.remove('donation-cta--animating')
    }, 400)
  }

  const hideDonationCta = () => {
    // exit if animation is already running
    if (donationCta.classList.contains('donation-cta--animating')) { return }

    donationCta.classList.add('donation-cta--hidden')
    setTimeout(() => {
      donationCta.style.visibility = 'hidden'
      donationCta.style.opacity = 0
    }, 400)

    // set animation class to prevent multiple animations
    donationCta.classList.add('donation-cta--animating')
    setTimeout(() => {
      donationCta.classList.remove('donation-cta--animating')
    }, 400)
  }

  // save last scroll position
  let lastScrollTop = 0

  // show/hide donation CTA on scroll
  const handleScroll = () => {
    // get current scroll position
    const scrollTop = document.documentElement.scrollTop

    if (scrollTop > lastScrollTop) { // scrolled down

      // Hide donation CTA when above footer and show otherwise
      const donationCtaPosition = donationCta.getBoundingClientRect().top
      const donationCtaHeight = donationCta.offsetHeight
      const footerPosition = footer.getBoundingClientRect().top
      const footerHeight = footer.offsetHeight

      if (donationCtaPosition + donationCtaHeight < footerPosition) {
        showDonationCta()
      } else if (donationCtaPosition > footerPosition + footerHeight) {
        showDonationCta()
      } else {
        hideDonationCta()
      }

    } else { // scrolled up
      hideDonationCta()
    }

    // save current scroll position as last scroll position
    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
  }

  handleScroll()
  window.addEventListener('scroll', () => { handleScroll() }, false)
}
