// find donation plugins and wrap frames in specific bg class
export const donationFrame = () => {
  if (!document.querySelectorAll('.donation-barometer')) { return }

  const donationBarometer = document.querySelectorAll('.donation-barometer')
  const donationButton = document.querySelectorAll('.donation-button')

  // find donation barometer and add bg class to frame
  if (donationBarometer.length) {
    donationBarometer.forEach((donationBarometer) => {

      const barometerWrapper = donationBarometer.parentNode

      barometerWrapper.classList.add('bg', 'bg--lightgrey')
    })
  }

  // find donation button and add bg class to frame
  if (donationButton.length) {
    donationButton.forEach((donationButton) => {

      const buttonWrapper = donationButton.parentNode

      buttonWrapper.classList.add('bg', 'bg--red')
    })
  }
}
