import Swiper, { Navigation, Pagination, A11y } from 'swiper'

export const gallery = () => {
  if (!document.querySelector('.swiper.swiper--gallery')) { return }

  // do not show a transition between slides if the user has opt out of animations
  const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)')?.matches
  const speed = prefersReducedMotion ? 0 : 300
  const allSwiperElements = document.querySelectorAll('.swiper.swiper--gallery')

  for (let i = 0; i < allSwiperElements.length; i++) {
    const swiperId = allSwiperElements[i].dataset.swiperId
    const galleryAnnouncements = document.getElementById(`galleryAnnouncements-${swiperId}`)
    const nextEl = '.swiper-button-next-' + swiperId
    const prevEl = '.swiper-button-prev-' + swiperId

    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(allSwiperElements[i], {
      modules: [Navigation, Pagination, A11y],
      loop: true,
      speed,
      preloadImages: false,
      a11y: {
        // eslint-disable-next-line no-undef
        prevSlideMessage: galleryNext,
        // eslint-disable-next-line no-undef
        nextSlideMessage: galleryPrev,
        // eslint-disable-next-line no-undef
        paginationBulletMessage: galleryPaginationBullet,
      },

      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },

      navigation: {
        nextEl,
        prevEl,
      },

      on: {
        // announce current silde for screenreader users
        slideChange: function () {
          const currentSlide = this.realIndex + 1

          // eslint-disable-next-line no-undef
          galleryAnnouncements.textContent = galleryImage + ' ' + currentSlide
        },
      },
    })
  }

  // keep navigation arrows centered to image
  const centerNavigations = () => {
    allSwiperElements.forEach((swiperElement) => {
      const swiperNavigationPrev = swiperElement.nextElementSibling
      const swiperNavigationNext = swiperNavigationPrev.nextElementSibling
      const swiperNavigationIdealTopValue = swiperElement.querySelector('[data-swiper-slide-index="0"] img').clientHeight / 2 + 'px'

      // apply 50% of image height to position of navigation from top
      swiperNavigationPrev.style.top = swiperNavigationIdealTopValue
      swiperNavigationNext.style.top = swiperNavigationIdealTopValue
    })
  }

  window.addEventListener('load', centerNavigations) // call after load
  window.addEventListener('resize', centerNavigations) // call on resize
}
