import Swiper, { Navigation, Pagination, A11y, FreeMode } from 'swiper'
/* eslint-disable no-undef */

/**
 * Video Galleries with Swiper
 */
export const videoSwiper = () => {
  // get all video swiper elements
  const videoSwiperElements = document.querySelectorAll('.video__swiper')

  // exit if no video swiper elements are found
  if (!videoSwiperElements.length) {
    return
  }

  // check if user prefers reduced motion
  const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)')?.matches

  // set animation speed to 0 if user prefers reduced motion
  const speed = prefersReducedMotion ? 0 : 300

  // initialize swiper for each video gallery
  videoSwiperElements.forEach((videoSwiperElement) => {
    const nextEl = videoSwiperElement.querySelector('.swiper-button-next')
    const prevEl = videoSwiperElement.querySelector('.swiper-button-prev')
    const announcements = videoSwiperElement.querySelector('.swiper-announcements')

    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(videoSwiperElement, {
      modules: [Navigation, Pagination, A11y, FreeMode],
      slidesPerView: 'auto',
      spaceBetween: 12,
      freeMode: true,
      speed,
      preloadImages: false,
      a11y: {
        prevSlideMessage: videoGalleryNext,
        nextSlideMessage: videoGalleryPrev,
        paginationBulletMessage: videoGalleryPaginationBullet,
      },

      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },

      navigation: {
        nextEl,
        prevEl,
      },

      breakpoints: {
        [960 + 96]: {
          spaceBetween: 32,
          centeredSlides: true,
          freeMode: false,
        },
      },

      on: {
        init () {
          // prevent focus on all slides except the active one
          this.slides.forEach((slide) => {
            if (slide !== this.slides[0]) {
              slide.querySelectorAll('iframe, video, button, a').forEach((element) => {
                element.setAttribute('tabindex', '-1')
              })
            }
          })
        },
        slideChange () {
          const currentSlide = this.realIndex + 1

          // prevent focus on all slides
          this.slides.forEach((slide) => {
            slide.querySelectorAll('iframe, video, button, a').forEach((element) => {
              element.setAttribute('tabindex', '-1')
            })
          })

          // enable focus on active slide
          this.slides[this.activeIndex].querySelectorAll('iframe, video, button, a').forEach((element) => {
            element.removeAttribute('tabindex')
          })

          // announce current slide for screen readers
          announcements.textContent = videoGalleryVideo + ' ' + currentSlide
        },
      },
    })
  })
}
