/**
 * Language menu
 */

export const languageMenu = () => {
  const languageMenuSelect = document.querySelectorAll('.language-menu__select')

  // Exit if no language menu
  if (!languageMenuSelect) return

  languageMenuSelect.forEach((select) => {

    // On change, redirect to the selected value/language
    select.addEventListener('change', (event) => {
      const languageMenu = event.target.closest('.language-menu')
      const selectedOption = event.target.options[event.target.selectedIndex]
      const selectedOptionValue = selectedOption.dataset.flag

      // Hide all flags
      languageMenu.querySelectorAll('.language-menu__flag').forEach((flag) => {
        flag.style.display = 'none'
      })

      // Show flag of selected language
      languageMenu.querySelector(`.language-menu__flag--${selectedOptionValue}`).style.display = 'block'

      // Redirect to selected language
      window.location.href = event.target.value
    })
  })
}

/**
 * Handle Language suggest
 */

export const languageSuggest = () => {
  const languageSuggest = document.querySelector('.language-suggest')

  // Exit if no language suggest
  if (!languageSuggest) return

  // using two letter language codes
  const htmlLanguage = document.documentElement.lang.substring(0, 2)
  const browserLanguage = navigator.language.substring(0, 2)

  // Exit if same language
  if (browserLanguage === htmlLanguage) { return }

  // Exit if language suggest was manually closed before
  if (sessionStorage.getItem('drk_language-suggest-closed') === 'true') { return }

  // Check if language suggest was already shown before (for this session)
  if (sessionStorage.getItem('drk_language-suggest-init-time') !== null) {
    const initTime = sessionStorage.getItem('drk_language-suggest-init-time')
    const currentTime = Date.now()
    const timeDiff = currentTime - initTime
    const timeDiffInSeconds = timeDiff / 1000

    // Exit if time difference is more than 1 minute
    if (timeDiffInSeconds > 60) { return }
  } else {
    // Create init time if it does not exist
    const currentTime = Date.now()
    sessionStorage.setItem('drk_language-suggest-init-time', currentTime.toString())
  }

  // Get language suggest elements
  const languageSuggestMessageCurrent = document.querySelector('.language-suggest__message--current')
  const languageSuggestMessageSuggest = document.querySelector('.language-suggest__message--suggest')
  const languageSuggestClose = document.querySelector('.language-suggest__close')

  // Get top style related elements
  const bodyWrapper = document.querySelector('.body__wrapper')
  const toolbarWrap = document.querySelector('.toolbar__wrap')
  const mainMenuWrap = document.querySelector('.main-menu__wrap')
  const subMenuBar = document.querySelector('.sub-menu__bar')
  const subMenuNavWrap = document.querySelector('.sub-menu__nav-wrap')
  const globalSearch = document.querySelector('.global-search')

  // Show language suggest
  languageSuggest.style.display = 'block'

  // Check if language is available and show messages
  if (browserLanguage === 'de' || browserLanguage === 'en') {
    languageSuggestMessageCurrent.querySelector(`[data-suggest-language="${htmlLanguage}"]`).style.display = 'block'
    languageSuggestMessageSuggest.querySelector(`[data-suggest-language="${browserLanguage}"]`).style.display = 'block'
    languageSuggestClose.querySelector(`[data-suggest-language="${htmlLanguage}"]`).style.display = 'block'
  } else {
    languageSuggestMessageCurrent.querySelector('[data-suggest-language="fallback"]').style.display = 'block'
    languageSuggestMessageSuggest.querySelector('[data-suggest-language="fallback"]').style.display = 'block'
    languageSuggestClose.querySelector('[data-suggest-language="fallback"]').style.display = 'block'
  }

  // Set top styles to reserve space for language suggest
  const reserveHeight = () => {
    // Get all height values
    let toolbarWrapHeight = toolbarWrap.offsetHeight
    const subMenuBarHeight = subMenuBar ? subMenuBar.offsetHeight : 0
    const languageSuggestHeight = languageSuggest ? languageSuggest.offsetHeight : 0

    bodyWrapper.style.paddingTop = `${languageSuggestHeight + toolbarWrapHeight + subMenuBarHeight}px`

    if (document.documentElement.classList.contains('hidden-toolbar')) { toolbarWrapHeight = 0 }

    toolbarWrap.style.top = `${languageSuggestHeight}px`
    mainMenuWrap.style.top = `${languageSuggestHeight + toolbarWrapHeight}px`
    mainMenuWrap.style.height = `calc(100% - ${languageSuggestHeight + toolbarWrapHeight}px)`
    if (subMenuBar) { subMenuBar.style.top = `${languageSuggestHeight + toolbarWrapHeight}px` }
    if (subMenuNavWrap) { subMenuNavWrap.style.top = `${languageSuggestHeight + toolbarWrapHeight + subMenuBarHeight}px` }
    globalSearch.style.top = `${12 + languageSuggestHeight}px`
  }

  // Reserve height on load, resize and scroll
  reserveHeight()
  window.addEventListener('resize', reserveHeight)
  window.addEventListener('scroll', reserveHeight)

  // Close language suggest and set session storage
  const closeLanguageSuggest = () => {
    languageSuggest.style.display = 'none'
    bodyWrapper.style.paddingTop = null
    toolbarWrap.style.top = null
    mainMenuWrap.style.top = null
    mainMenuWrap.style.height = null
    subMenuBar.style.top = null
    subMenuNavWrap.style.top = null
    globalSearch.style.top = null

    window.removeEventListener('resize', reserveHeight)
    window.removeEventListener('scroll', reserveHeight)

    // Set session storage that language suggest was closed
    sessionStorage.setItem('drk_language-suggest-closed', 'true')
  }

  // Close language suggest on click on close button
  languageSuggestClose.addEventListener('click', closeLanguageSuggest)

  // If language select is changed, set session storage that language suggest was closed
  const languageSelect = languageSuggest.querySelector('.language-menu__select')
  languageSelect.addEventListener('change', () => {
    sessionStorage.setItem('drk_language-suggest-closed', 'true')
  })

  // Close language suggest after 1 minute
  setTimeout(closeLanguageSuggest, 60000)
}
