/**
 * Auto create telephone links
 */

export const initTelephoneLinks = () => {
  const targetNodes = document.querySelectorAll('.js-create-telephone-link')

  targetNodes.forEach((originalNode) => {
    const telephoneNumber = originalNode.innerHTML

    // Clean up telephone number by removing "(0)" and all non-digit characters except "+"
    const cleanedTelephoneNumber = telephoneNumber.replace(/\(0\)/g, '').replace(/[^0-9+]/g, '')

    // Create new link with cleaned telephone number as href and original classes
    const link = document.createElement('a')
    link.href = `tel:${cleanedTelephoneNumber}`
    link.classList.add(...originalNode.classList)
    link.innerHTML = telephoneNumber

    // Replace old node with new link
    originalNode.parentNode.replaceChild(link, originalNode)
  })
}
