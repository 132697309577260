export const membershipApplication = () => {

  // eslint-disable-next-line
  const Membership = {
    $form: null,
    $zipField: null,
    $mailField: null,
    $urlField: null,
    $chapterNameField: null,
    $chapterSelectField: null,
    $chapterSelectContainer: null,
    $firstChapterSelectOption: null,
    $submitButton: null,
    $informationField: null,
    errorClass: 'parsley-error',
    // Standard-Konfiguration
    _timeout: 500,
    /**
     * start referencing fields if correct form class is found
     */
    init: function () {
      // jQuery-Referenz auf das Formular-Element
      this.$form = $('.o-form--membership__application').parent('form')

      if (this.$form.length) {
        this.addParsleyValidator()
        // jQuery-Referenz auf das Eingabefeld für die Postleitzahl
        this.$zipField = $('#powermail_field_zip')
        // jQuery-Referenz auf das Eingabefeld für den Kreisverband
        this.$chapterSelectField = this.$form.find('#powermail_field_chapter')
        this.$chapterSelectContainer = this.$form.find('#powermail_fieldwrap_145')
        this.$urlField = this.$form.find('#powermail_field_organisation_url')
        this.$mailField = this.$form.find('#powermail_field_organisation_mail')
        this.$informationField = this.$form.find('#powermail_fieldwrap_148').addClass('hidden')
        this.$requiredText = this.$form.find('#powermail_fieldwrap_149')
        this.$chapterNameField = this.$form.find('#powermail_field_kreisverbandname')
        this.$submitButton = this.$form.find('.powermail_submit')
        this.$firstChapterSelectOption = this.$chapterSelectField.find('option:first').clone()
        // Objekt nur initiieren, wenn auch eine URL ausgelesen werden konnte
        this.$zipField.on('keyup change input', $.proxy(this._handleZipInput, this))
        // on page reload if zip is filled start chapter request
        if (this.$zipField.val().length >= 3) {
          this._requestLocalChapters()
        }
      }
    },
    addParsleyValidator: function () {
      window.Parsley
        .addValidator('validateChapter', {
          requirementType: 'integer',
          validateNumber: function (value, requirement) {
            return value > 0
          },
          messages: {
            de: 'Bitte wählen Sie einen Kreisverband aus!',
            en: 'Please choose a local chapter!',
          },
        })
    },

    /**
     * Ziel-URL für die Ajax-Anfragen
     *
     * @returns {string}
     */
    getRequestUrl: function () {
      return this.$zipField.attr('data-zipsuggesturl')
    },

    /**
     * Funktion handhabt Eingaben im PLZ-Feld und wartet bis die Eingabe
     * vollständig ist, bis eine Anfrage ausgelöst wird
     *
     * @private
     */
    _handleZipInput: function () {
      if (this.$zipField.val().length >= 3) {
        clearTimeout(this._timeoutId)
        this._timeoutId = setTimeout(
          $.proxy(this._requestLocalChapters, this),
          this._timeout,
        )
      }
    },

    //
    /**
     * Funktion fragt bei gegebener PLZ die dazugehörigen Kreisverbände beim Server an
     *
     * @private
     */
    _requestLocalChapters: function () {
      const zipcode = this.$zipField.val()
      this._oldZipcode = zipcode
      this.$zipField.addClass(this.loadingClass)
      $.ajax({
        method: 'get',
        dataType: 'json',
        url: this.getRequestUrl() + zipcode,
        success: $.proxy(this._handleResponse, this),
        error: $.proxy(this._handleError, this, this.defaultError),
      })
    },

    /**
     * Funktion wertet die Server-Antwort und die Kreisverbandsdaten aus und handelt entsprechend
     *
     * @param {json} response
     * @private
     */
    _handleResponse: function (response) {
      let chapters, orgId
      this.$zipField.removeClass(this.loadingClass)
      if (response.orgdata) {
        chapters = []
        for (orgId in response.orgdata) {
          // eslint-disable-next-line no-prototype-builtins
          if (response.orgdata.hasOwnProperty(orgId)) {
            chapters.push(response.orgdata[orgId])
          }
        }

        if (chapters.length > 0) {
          this._renderChapterSelection(chapters)
        }

        this._handleSuccess()
      } else {
        this._handleError(response.warning)
        this._resetChapterInformation()
        this._hideChapterSelection()
      }
    },

    /**
     *
     * Funktion erzeugt ein Auswahlfeld, falls mehrere mögliche
     * Kreisverbände zur Auswahl stehen
     *
     * @param {array} chapters
     * @private
     */
    _renderChapterSelection: function (chapters) {
      let options = ''; let i; const len = chapters.length
      // add "Please choose" option if more then 1 element
      if (len > 1) {
        options += this.$firstChapterSelectOption.prop('outerHTML')
      }
      for (i = 0; i < len; i += 1) {
        if (i === 0) {
          options +=
            '<option data-mail="' + chapters[i].data.mail + '" ' +
            'data-name="' + chapters[i].name + '"' +
            'data-id="' + chapters[i].data.id + '"' +
            'data-url="' + chapters[i].data.url + '" value="' +
            chapters[i].data.id +
            '" selected="selected">' +
            chapters[i].name +
            '</option>'
        } else {
          options +=
            '<option data-mail="' + chapters[i].data.mail + '" ' +
            'data-name="' + chapters[i].name + '"' +
            'data-id="' + chapters[i].data.id + '"' +
            'data-url="' + chapters[i].data.url + '" value="' +
            chapters[i].data.id +
            '">' +
            chapters[i].name +
            '</option>'
        }
      }
      this.$chapterSelectField.find('option').remove()
      this.$chapterSelectField.append(options)
      this.$chapterSelectContainer.removeClass('hidden')
      this.$chapterSelectField.on('change',
        $.proxy(this._handleSelectChange, this))

      if (len === 1) {
        this._updateChapterInformation(
          this.$chapterSelectField.find('option:first'),
        )
      }
    },

    /**
     *
     * Funktion nimmt Änderungen am Formular vor, falls ein Fehler vorliegt
     *
     * @param {string} message
     * @private
     */
    _handleError: function (message) {

      if ($('#chapter-error').length === 0) {

        if (message === undefined) {
          message = 'Hier hat etwas nicht funktioniert! '
          message += 'Mit einem Klick auf unten stehenden Button werden'
          message += ' Sie zu unserem Online-Formular weitergeleitet.'
        }

        this.$error = $('<p id="chapter-error" class="o-form__non-parsley-error"></p>')
        this.$error.html(message)
        this.$requiredText.before(this.$error)
      }

      // this.$zipField.addClass(this.errorClass);
      this.$chapterSelectContainer.addClass('hidden')
      this.$informationField.addClass('hidden')
    },

    /**
     *
     * Funktion macht Änderungen der Funktion _handleError rückgängig
     *
     * @private
     */
    _handleSuccess: function () {
      if (this.$error) {
        // this.$zipField.removeClass(this.errorClass);
        $('#chapter-error', this.$form).remove()
      }
      // write chapter info to form
    },

    /**
     *
     * Funktion fängt Änderungen an der Kreisverbandsauswahl ab und
     * lässt die Kreisverbandsdaten aktualisieren
     *
     * @param {Object} e
     * @private
     */
    _handleSelectChange: function (e) {
      this._updateChapterInformation($(e.target).find('option:selected'))
    },

    /**
     *
     * Funktion entfernt die Auswahl für Kreisverbände
     *
     * @private
     */
    _hideChapterSelection: function () {
      if (this.$chapterSelectField) {
        this.$chapterSelectContainer.addClass('hidden')
        this.$informationField.addClass('hidden')
      }
    },

    /**
     *
     * Funktion aktualisiert auf Basis der Kreisverbandsdaten die
     * definierten Felder innerhalb des Formulars
     *
     * @param {Object} selectedOption
     * @private
     */
    _updateChapterInformation: function (selectedOption) {
      if (selectedOption !== undefined && selectedOption.val() !== '-1') {
        this.$mailField.val(selectedOption.data('mail'))
        this.$urlField.val(selectedOption.data('url'))
        this.$chapterNameField.val(selectedOption.data('name'))
        this.$informationField.removeClass('hidden')
      } else {
        this._resetChapterInformation()
      }
    },

    /**
     *
     * Funktion leert vorher ausgefüllte Felder für Kreisverbandsdaten
     * im Fehlerfall
     *
     * @private
     */
    _resetChapterInformation: function () {
      this.$mailField.val('')
      this.$urlField.val('')
      this.$chapterNameField.val('')
      this.$informationField.addClass('hidden')
    },
  }

  if (document.querySelector('.o-form--membership__application')) {
    Membership.init()
  }

}
