/**
 * This will add a CSS custom property for the actual vw without scrollbar
 */

export const setViewWidth = () => {
  function setCurrentViewWidth () {
    const clientVw = document.documentElement.clientWidth
    document.documentElement.style.setProperty('--client-vw', `${clientVw}px`)
  }

  setCurrentViewWidth()
  window.addEventListener('resize', setCurrentViewWidth)
}

/**
 * Reserve space for all menus and toolbars in fixed header
 */

export const calculateHeaderHeight = () => {
  const bodyWrapper = document.querySelector('.body__wrapper')
  const languageSuggest = document.querySelector('.language-suggest')
  const toolbarWrap = document.querySelector('.toolbar__wrap')
  const subMenuBar = document.querySelector('.sub-menu__bar')

  // Exit if none of the elements are available
  if (!bodyWrapper || !toolbarWrap) { return }

  // Get all height values
  const languageSuggestHeight = languageSuggest ? languageSuggest.offsetHeight : 0
  const toolbarWrapHeight = toolbarWrap.offsetHeight
  const subMenuBarHeight = subMenuBar ? subMenuBar.offsetHeight : 0

  bodyWrapper.style.paddingTop = `${languageSuggestHeight + toolbarWrapHeight + subMenuBarHeight}px`
}

export const reserveHeaderHeight = () => {
  calculateHeaderHeight()

  // Reserve height on load, resize and scroll
  calculateHeaderHeight()
  window.addEventListener('resize', calculateHeaderHeight)
}
