/**
 * This function returns all parents of an element
 * @param elem Element
 * @returns {Array.<HTMLElement>} Parents
 */

export const getParents = (elem) => {
  const parents = []
  while (elem.parentNode && elem.parentNode.nodeName.toLowerCase() !== 'body') {
    elem = elem.parentNode
    parents.push(elem)
  }
  return parents
}
