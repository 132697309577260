/**
 *  form_formframework helper functions
 */

export const handleForms = () => {

  const formSelects = $('select')
  const formTxtInputs = $('input, textarea')

  // wrap forms with inner class
  $('.frame-type-form_formframework').wrapInner('<div class="inner"></div>')

  // Init custom file inputs
  $(document).ready(function () {
    $('input[type="file"]').each(function () {
      $(this).addClass('custom-file-input')
      $(this).parent().addClass('custom-file')
      const customLabel = '<label class="custom-file-label" for="' + $(this).attr('id') + '">&nbsp;</label>'
      $(customLabel).insertAfter($(this))
    })
    // eslint-disable-next-line no-undef
    bsCustomFileInput.init()
  })

  // Watch for changes and add filled classes for all inputs
  formTxtInputs.on('keydown keypress keyup paste blur change', function () {
    if ($(this).val()) {
      $(this).addClass('filled')
    } else {
      $(this).removeClass('filled filled--focusout')
    }
  })

  formTxtInputs.on('focusout', function () {
    if ($(this).val()) {
      $(this).addClass('filled--focusout')
    } else {
      $(this).removeClass('filled--focusout')
    }
  })

  // selects
  formSelects.on('change', function () {
    const selectedOption = $(this).children('option:not(:first-child):selected').val()

    if (selectedOption) {
      $(this).addClass('filled')
    } else {
      $(this).removeClass('filled')
    }
  })

  // custom downloads
  $('input[type=file]').on('change', function () {
    const selectedFile = $(this).val()

    if (selectedFile) {
      $(this).addClass('filled')
    } else {
      $(this).removeClass('filled')
    }
  })

  // adding additional classes

  const radioButtonGroup = $('.inputs-list .radio').closest('.inputs-list').closest('.form-group')
  radioButtonGroup.each(function () {
    $(this).addClass('form-group-radio')
  })

  const checkboxGroup = $('.inputs-list .form-check').closest('.inputs-list').closest('.form-group')
  checkboxGroup.each(function () {
    $(this).addClass('form-group-checkbox')
  })

  const checkboxGroupLabel = $('.form-group-checkbox').find('.control-label')
  checkboxGroupLabel.each(function () {
    $(this).addClass('form-group-label')
  })

  const radioGroupLabel = $('.form-group-radio').find('.control-label')
  radioGroupLabel.each(function () {
    $(this).addClass('form-group-label')
  })

  // Auto resize text areas on input
  const textAreas = document.querySelectorAll('textarea')

  textAreas.forEach((textArea) => {
    textArea.setAttribute('style', 'height:' + (textArea.scrollHeight) + 'px;overflow-y:hidden;')
    textArea.addEventListener('input', function () {
      this.style.height = 'auto'
      this.style.height = (this.scrollHeight) + 'px'
    }, false)
  })
}
